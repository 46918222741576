import axiosInstance from '@services/axios';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { IAnswerRequest, IUpdateAnswerRequest, TAnswerResponse, TAnswersResponse } from './interfaces';

dayjs.extend(utc);
dayjs.extend(timezone);

class AnswersService {
  async saveAnswers(params: IAnswerRequest): Promise<TAnswerResponse> {
    const response = await axiosInstance.post<TAnswerResponse>('/answers/patient', params);

    return response.data;
  }

  async updateAnswers({ fields, id }: IUpdateAnswerRequest): Promise<TAnswerResponse> {
    const response = await axiosInstance.patch<TAnswerResponse>(`/answers/patient/${id}`, fields);

    return response.data;
  }

  async getAnswers(): Promise<TAnswersResponse> {
    const timezone = dayjs.tz.guess();
    const response = await axiosInstance.get<TAnswersResponse>('/answers/patient', { params: { timezone } });

    return response.data;
  }

  async getAnswerById(id: string): Promise<TAnswerResponse> {
    const timezone = dayjs.tz.guess();
    const response = await axiosInstance.get<TAnswerResponse>(`/answers/patient/${id}`, { params: { timezone } });

    return response.data;
  }
}

export const Answers = new AnswersService();
